import { render, staticRenderFns } from "./DashboardComponent.vue?vue&type=template&id=7a4c41a4&scoped=true&"
import script from "./DashboardComponent.vue?vue&type=script&lang=js&"
export * from "./DashboardComponent.vue?vue&type=script&lang=js&"
import style0 from "./DashboardComponent.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DashboardComponent.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./DashboardComponent.vue?vue&type=style&index=2&id=7a4c41a4&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a4c41a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VChip,VCol,VDataTable,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSkeletonLoader,VSlideGroup,VSlideItem,VSpacer,VTooltip})
