<template>
  <v-container>
    {{ chang() }}
  
    <dashboard-component></dashboard-component>

    <v-row class="mx-0">
      <v-col cols="12" md="12" class="pa-0 ">
        <v-skeleton-loader
          :loading="loading"
          translate="trans"
          type=" list-item, divider, article, article"
        >
          <v-card class="mt-5 " flat>
            <v-card-title class="">
              <h4 class="font-weight-light">{{ $t("home.lastAlert") }}</h4>
              <v-spacer></v-spacer>
              <v-btn color="primary" :to="'/alerts'">
                {{ $t("home.seeAll") }}
              </v-btn>
            </v-card-title>
            <v-card-text class="py-0">
              <v-timeline dense v-if="alerts[0]">
                <v-slide-x-reverse-transition group hide-on-leave>
                  <v-timeline-item
                    v-for="(item, i) in alerts"
                    :key="i"
                    color="teal  lighten-1"
                    icon="mdi-information-variant"
                    fill-dot
                  >
                    <v-card outlined class="">
                      <v-card-title class="py-1">
                        <h5 class="teal--text font-weight-light">
                          {{ item.title }}
                        </h5>
                        <v-spacer />
                        <h6
                          class="teal--text font-weight-light"
                          style="direction: ltr"
                        >
                          {{ item.datex | moment("YYYY/MM/DD - h:mm A ") }}
                        </h6>
                      </v-card-title>
                      <v-divider class=""></v-divider>

                      <v-card-text class="pt-4">
                        <p v-if="item.details.length <= 160">
                          {{ item.details }}
                        </p>
                        <div v-else>
                          <p :class="item.more ? '' : 'headerClass'">
                            {{ item.details }}
                          </p>
                          <a @click="seeMoreFun(i)">{{
                            item.more
                              ? $t("home.MinimizeText")
                              : $t("home.ContinueReading")
                          }}</a>
                        </div>
                        <v-card-actions
                          v-if="
                            item.attachedFiles && item.attachedFiles.length > 0
                          "
                        >
                          <v-btn depressed @click="setShowAttachments(i)">
                            {{ $t("mail.Attachments") }} ({{
                              item.attachedFiles.length
                            }})
                            <v-icon>
                              {{
                                item.showAttachments
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon
                            >
                          </v-btn>
                        </v-card-actions>

                        <v-expand-transition
                          v-if="
                            item.attachedFiles && item.attachedFiles.length > 0
                          "
                        >
                          <div v-show="item.showAttachments">
                            <v-divider></v-divider>

                            <v-card-text>
                              <v-row class="ma-2">
                                <v-col
                                  cols="auto"
                                  v-for="file in item.attachedFiles"
                                  :key="file.name"
                                >
                                  <Download-File
                                    :iconWidth="50"
                                    :width="250"
                                    :fileSizeFormat="file.fileSizeFormat"
                                    :fileUrl="file.fileUrl"
                                    :key="file.name"
                                  ></Download-File>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-slide-x-reverse-transition>
              </v-timeline>
              <div class="pa-4 text-center" v-else>
                <img width="100px" src="../../assets/noData.svg" />
                <h2 class="py-4 primary2--text">
                  {{ $t("home.ThereAreNoNotifications") }}
                </h2>
              </div>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>

    </v-row>
    <v-row v-resize="onResize" class="mt-3">
      <v-col cols="12" lg="12">
        <v-sheet id="hide" :elevation="0" class="pa-0" >
          <v-card flat color="" class="rounded-t pa-3"  v-if="isInRole(2)">
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  :items="items"
                  v-model="selected"
                  :label="$t('home.scheduleChoese')"
                  outlined
                  dense
                  @change="getSchedule()"
                ></v-select
              ></v-col>
              <v-col v-if="selected == 2">
                <v-select
                  hide-details
                  :items="sections"
                  v-model="selectDep"
                  :label="$t('home.scheduleChoese')"
                  outlined
                  dense
                  @change="getSchedule()"
                ></v-select
              ></v-col>
            </v-row>
          </v-card>

          <v-row v-if="!dataSource[0]" no-gutters>
            <v-col cols="12" md="12" class="text-center">
              <img src="../../assets/search.svg" width="200px" class="" />
              <h3>{{ $t("noData") }}</h3>
            </v-col></v-row>
          <DxScheduler
            v-if="dataSource[0]"
            :start-day-hour="7"
            :end-day-hour="22"
            :data-source="dataSource"
            :current-date="beginningOfWeek"
            :views="['agenda', 'week']"
            :adaptivity-enabled="true"
            :editing="false"
            :rtl-enabled="$i18n.locale == 'ar' ? true : false"
            :show-all-day-panel="false"
            :on-appointment-click="onAppointmentClick"
            :on-appointment-dbl-click="onAppointmentClick"
            :onAppointmentFormOpening="onAppointmentClick2"
            :group-by-date="false"
            appointment-tooltip-template="AppointmentTooltipTemplateSlot"
            :on-content-ready="onContentReady"
            :on-appointment-rendered="onAppointmentRendered"
            appointment-template="AppointmentTemplateSlot"
            :current-view="currentView"
            :on-option-changed="onOptionChanged"
            :show-current-time-indicator="true"
            :shade-until-current-time="false"
            ref="DxScheduler01"
            
            
          >
            <DxView
              type="week"
              :week-duration="7"
              date-cell-template="dateWeekCellSlot"
              max-appointments-per-cell="auto"
            />
            <DxView
              type="agenda"
              :agenda-duration="7"
              date-cell-template="dateAgendaCellSlot"
              max-appointments-per-cell="auto"
            />
            <DxResource
              :data-source="lableName"
              :use-color-as-default="true"
              field-expr="label"
              label="Priority"
            />
        
            <template #AppointmentTemplateSlot="{ data,index }">
              <div :class="$vuetify.theme.dark ? 'white--text pa-2':'pa-2'" :key="index">
                <div class="text-wrap">
                  <span>{{ data.appointmentData.text }}</span>
                  <span class="mx-1"
                    style="font-size: smaller"
                    v-if="data.appointmentData.description"
                    >{{ " (" + data.appointmentData.description + ") " }}</span
                  >
                </div>

                <div class="mx-1">
                  {{ $t("home.from") }} :{{
                    data.appointmentData.startDate | moment(" h:mm A ")
                  }}
                  {{ $t("home.to") }} :{{
                    data.appointmentData.endDate | moment(" h:mm A ")
                  }}
                </div>
              </div>
            </template>

            <template #dateAgendaCellSlot="{ data: dateCell,index }">
              <div class="name pt-6"  :key="index">
                <h3>{{ dayOfWeekNames[dateCell.date.getDay()] }}</h3>
              </div>
            </template>
            <template #dateWeekCellSlot="{ data: dateCell ,index}">
              <div style="text-align: center" :key="index">
                <h4>{{ dayOfWeekNames[dateCell.date.getDay()] }}</h4>
              </div>
            </template>

            <template #AppointmentTooltipTemplateSlot="{ data,index }">
              <div class="" :key="index">
                <p>
                  {{ $t("home.lecTeacher") }} :
                  <b>{{ data.appointmentData.teacherName }}</b>
                </p>
                <p>
                  {{ $t("home.fromHour") }} :
                  <b>{{
                    data.appointmentData.startDate | moment(" h:mm A ")
                  }}</b>
                </p>
                <p>
                  {{ $t("home.toHour") }} :
                  <b>{{ data.appointmentData.endDate | moment(" h:mm A ") }}</b>
                </p>
                <p>
                  {{ $t("home.lecLocation") }} :
                  <b>{{ data.appointmentData.roomLocation }}</b>
                </p>
                <p>
                  {{ $t("home.lecName") }} :
                  <b>{{ data.appointmentData.roomName }}</b>
                </p>
                <p>
                  {{ $t("home.numstol") }} :
                  <b>{{ data.appointmentData.roomCapacity }}</b>
                </p>
                <p>
                  {{ $t("home.description") }} :
                  <b>{{ data.appointmentData.description }}</b>
                </p>
              </div>
            </template>
          </DxScheduler>

          <v-dialog v-model="dialog" max-width="450px">
            <v-card color="" max-width="450px" flat>
              <v-toolbar :color="dialogInfo.color" dark>
                <v-toolbar-title >{{dialogInfo.text}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <p class="pt-3">( {{ dialogInfo.description }} )</p>
              </v-toolbar>
              <v-card-text class="pt-6">
                <p>
                  {{ $t("home.lecTeacher") }} :
                  <b>{{ dialogInfo.teacherName }}</b>
                </p>
                <p>
                  {{ $t("home.fromHour") }} :
                  <b v-if="dialogInfo.startDate">{{
                    dialogInfo.startDate | moment(" h:mm A ")
                  }}</b>
                </p>
                <p>
                  {{ $t("home.toHour") }} :
                  <b v-if="dialogInfo.endDate">{{
                    dialogInfo.endDate | moment(" h:mm A ")
                  }}</b>
                </p>
                <p>
                  {{ $t("home.lecLocation") }} :
                  <b>{{ dialogInfo.roomLocation }}</b>
                </p>
                <p>
                  {{ $t("home.lecName") }} : <b>{{ dialogInfo.roomName }}</b>
                </p>
                <p>
                  {{ $t("home.numstol") }} :
                  <b>{{ dialogInfo.roomCapacity }}</b>
                </p>
                <p>
                  {{ $t("home.description") }} :
                  <b>{{ dialogInfo.description }}</b>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="red" @click="dialog = false">
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import { DxScheduler, DxView, DxResource } from "devextreme-vue/scheduler";
import DashboardComponent from "./components/core/DashboardComponent.vue";
import DownloadFile from "../../components/downloadFile.vue";

export default {
  components: {
    DxScheduler,
    DxResource,
    DxView,
    DashboardComponent,
    DownloadFile,
  },
  name: "Dashboard",
  data() {
    return {
      showAttachments: false,
      dialog: false,
      scheduler: null,
      degreesLoading: false,
      dayOfWeekNames: [
        this.$t("weekday.sun"),
        this.$t("weekday.mon"),
        this.$t("weekday.tue"),
        this.$t("weekday.wed"),
        this.$t("weekday.thu"),
        this.$t("weekday.fri"),
        this.$t("weekday.sat"),
      ],
      lableName: [
        {
          text: "رمادي",
          id: 0,
          color: "#607D8B",
        },
        {
          text: "احمر",
          id: 1,
          color: "#F44336",
        },
        {
          text: "ازرق",
          id: 2,
          color: "#2196F3",
        },
        {
          text: "اخضر",
          id: 3,
          color: "#4CAF50",
        },
        {
          text: "برتقالي",
          id: 4,
          color: "#FF9800",
        },
      ],
      toggle_exclusive: 0,
      views: ["agenda", "week"],
      currentDate: new Date(),
      dataSource: [],
      items: [
        {
          text: this.$t("home.mySchedule"),
          value: 0,
        },
        {
          text: this.$t("home.followedTable"),
          value: 1,
        },
        {
          text: this.$t("home.PartitionTable"),
          value: 2,
        },
      ],
      selected: 0,
      seeMore: false,
      weekday: [6, 0, 1, 2, 3, 4, 5],
      loading2: false,
      type: "week",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "#2196F3",
        "#3F51B5",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#607D8B",
      ],
      names: ["Meeting"],

      loading: false,
      alerts: [],
      interval: null,
      beginningOfWeek: null,
      beginningOfWeek2: null,
      nonce: 2,
      sections: [],
      selectDep: "",
      checkFinal: false,
      checkFinalDate: "",
      doit: 0,
      windowSize: {
        x: 0,
        y: 0,
      },
      dialogInfo: {
        color: "",
      },
      scrolling: {
        mode: "horizontal",
      },
      currentView: "week",
    };
  },
  watch: {},
  mounted() {
    this.onResize();
  },
  activated() {},
  computed: {},
  created() {
    this.getAleart();
    this.getWeek();
    this.getSection();
    if (this.isInRole(0)) {
      // console.log(this.$store.state.userData);
      this.selectDep = this.$store.state.userData.sectionGuid;
      this.getSchedule();
    } else {
      this.getSchedule();
    }
    this.currentView =
      localStorage.getItem("schedulerCurrentView") != "agenda" &&
      localStorage.getItem("schedulerCurrentView") != "week"
        ? "week"
        : localStorage.getItem("schedulerCurrentView");
       
       
       

      
  },
  methods: {
    onOptionChanged(e) {
      //console.log(e);
      if (e.name == "currentView") {
        localStorage.setItem("schedulerCurrentView", e.value);
      }
    },
    onContentReady(e) {
      this.scheduler = e.component;
    },
    onAppointmentRendered(e) {},

    onAppointmentClick: function (e) {
      e.cancel = true;
      this.dialogInfo = e.appointmentData;
      this.dialog = true;

      // console.log(e.appointmentData);
    },
    onAppointmentClick2: function (e) {
      e.cancel = true;
    },
    chang() {
      this.items[0].text = this.$t("home.mySchedule");
      this.items[1].text = this.$t("home.followedTable");
      this.items[2].text = this.$t("home.PartitionTable");
      this.dayOfWeekNames = [
        this.$t("weekday.sun"),
        this.$t("weekday.mon"),
        this.$t("weekday.tue"),
        this.$t("weekday.wed"),
        this.$t("weekday.thu"),
        this.$t("weekday.fri"),
        this.$t("weekday.sat"),
      ];
    },
    getWeek() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      // var dateTime = date + " " + time;
      var date1 = new Date(date);
      var oneJan = new Date(date1.getFullYear(), 0, 1);
      var numberOfDays = Math.floor((date1 - oneJan) / (24 * 60 * 60 * 1000));
      var result = Math.ceil((date1.getDay() + 1 + numberOfDays) / 7);
      this.beginningOfWeek = this.$moment().week(result).startOf("week")._d;
      this.beginningOfWeek2 = this.$moment()
        .week(result + 1)
        .startOf("week")._d;
      // var endOfWeek = this.$moment().week(result).startOf("week").add(6, "days");
      // console.log( this.beginningOfWeek +'  - '+ this.beginningOfWeek2);
      // console.log(endOfWeek._d.toString().substring(0,4));
    },
    seeMoreFun(i) {
      this.alerts[i].more = !this.alerts[i].more;
      this.loading = true;
      this.loading = false;
    },
    getEventColor(event) {
      return event.color;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);
      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);
        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }
      // console.log(events);
      // this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    setShowAttachments(i) {
      this.alerts[i].showAttachments = !this.alerts[i].showAttachments;
      this.$forceUpdate();
    },
    getAleart() {
      this.loading = true;
      this.axios
        .get("Alerts/Get?all=false")
        .then((res) => {
          this.alerts = res.data.data;
          for (let i = 0; i < this.alerts.length; i++) {
            this.alerts[i].more = false;
            this.alerts[i].showAttachments = false;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getSection() {
      if (!this.isInRole(2)) {
        return;
      }
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          this.sections = res.data.data.sections;
          for (let i = 0; i < this.sections.length; i++) {
            this.sections[
              i
            ].text = ` ${this.sections[i].departmentName} / ${this.sections[i].stageName} / ${this.sections[i].studyName} / ${this.sections[i].sectionName}`;
            this.sections[i].value = this.sections[i].sectionGuid;
          }
        })
        .catch(() => {});
    },
    getSchedule() {
      this.loading2 = true;
      this.axios
        .get(
          `Schedule/Get?SectionGuid=${this.selectDep}&ScheduleType=${this.selected}`
        )
        .then((res) => {
          const schedule = res.data.data;
          for (let i = 0; i < schedule.length; i++) {
            schedule[i].name = schedule[i].subjectName;
            schedule[i].start = schedule[i].fromTime.substring(11, 16);
            schedule[i].end = schedule[i].toTime.substring(11, 16);
            schedule[i].color =
              schedule[i].label == 0
                ? "#607D8B"
                : schedule[i].label == 1
                ? "#F44336"
                : schedule[i].label == 2
                ? "#2196F3"
                : schedule[i].label == 3
                ? "#4CAF50"
                : schedule[i].label == 4
                ? "#FF9800"
                : "";
            schedule[i].timed = true;
            // this.colors[this.rnd(0, this.colors.length - 1)];
            let nowDate = this.beginningOfWeek.toISOString().substring(0, 10);
            let startDate = new Date(nowDate);
            let nowDate2 = this.beginningOfWeek2.toISOString().substring(0, 10);
            let startDate2 = new Date(nowDate2);
            let day = 60 * 60 * 24 * 1000;

            // console.log(startDate+' - '+startDate2);
            switch (schedule[i].day) {
              case 6:
                schedule[i].start2 =
                  new Date(startDate2.getTime()).toISOString().substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime()).toISOString().substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime()).toISOString().substr(0, 10) +
                  " " +
                  schedule[i].start;

                schedule[i].end =
                  new Date(startDate.getTime()).toISOString().substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 0:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 1)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 1)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 1)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 1)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 1:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 2)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 2)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 2)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 2)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 2:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 3)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 3)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 3)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 3)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 3:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 4)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 4)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 4)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 4)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 4:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 5)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 5)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 5)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 5)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              case 5:
                schedule[i].start2 =
                  new Date(startDate2.getTime() + day * 6)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end2 =
                  new Date(startDate2.getTime() + day * 6)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;
                schedule[i].start =
                  new Date(startDate.getTime() + day * 6)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].start;
                schedule[i].end =
                  new Date(startDate.getTime() + day * 6)
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  schedule[i].end;

                break;
              default:
                break;
            }
            schedule[i].timeStart = schedule[i].start;
            schedule[i].timeEnd = schedule[i].end;
          }
          const schedule2 = JSON.parse(JSON.stringify(schedule));
          // schedule.forEach((elem) => {
          //   schedule2.push(elem);
          // });
          // console.log(schedule2)
          for (let i = 0; i < schedule2.length; i++) {
            schedule2[i].start = schedule2[i].start2;
            schedule2[i].end = schedule2[i].end2;
          }

          const allSchedule = [...schedule, ...schedule2];
          // console.log(allSchedule);
          this.dataSource = [];
          for (let i = 0; i < allSchedule.length; i++) {
            this.dataSource.push({
              guid:allSchedule[i].guid,
              text: allSchedule[i].name,
              startDate: new Date(allSchedule[i].start),
              endDate: new Date(allSchedule[i].end),
              color: allSchedule[i].color,
              label: allSchedule[i].label,
              roomCapacity: allSchedule[i].roomCapacity,
              roomName: allSchedule[i].roomName,
              roomLocation: allSchedule[i].roomLocation,
              teacherName: allSchedule[i].teacherName,
              description: allSchedule[i].description,
            });
          }
          // console.log(this.dataSource);

          this.events = allSchedule;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading2 = false;
        });
      // console.log(this.events);
    },
    onResize() {
      clearTimeout(this.doit);
      this.doit = setTimeout(this.refreshScheduler, 300);
    },
    refreshScheduler() {
      if (this.$refs["DxScheduler01"]) {
        this.$refs["DxScheduler01"].instance.getDataSource().reload();
      }
    },
  },
};
</script>

<style lang="scss">
$calendar-event-border-width: "10px";

.bord {
  border-color: #0c65af !important;
}
#hide {
  overflow-y: hidden !important;
}
.v-calendar-daily__scroll-area {
  overflow-y: hidden !important;
}
.v-calendar-daily_head-day-label {
  display: none;
}
.v-calendar-daily_head-weekday {
  padding: 30px 0px 30px 0px !important;
}
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-calendar .v-event-timed {
  overflow: visible !important;
  white-space: inherit !important;
  text-overflow: ellipsis !important;
  padding: 2px 15px;
}
.fontWeight {
  font-weight: lighter !important;
}
.v-select__selection--comma {
  overflow: visible !important;
}
.v-list-item__title,
.v-list-item__subtitle {
  overflow: visible !important;
}

.dx-scheduler-agenda
  .dx-scheduler-appointment-content
  .dx-scheduler-appointment-content-allday,
.dx-scheduler-agenda
  .dx-scheduler-appointment-content
  .dx-scheduler-appointment-content-date,
.dx-scheduler-appointment-content .dx-scheduler-appointment-content-allday,
.dx-scheduler-appointment-content .dx-scheduler-appointment-content-date {
  color: black;
  font-size: 14px;
}
.dx-scheduler-agenda
  .dx-scheduler-appointment-content
  .dx-scheduler-appointment-title,
.dx-scheduler-appointment-content .dx-scheduler-appointment-title {
  font-size: 18px;
  color: black;
  padding-bottom: 5px;
}
.dx-scheduler-appointment-content,
.dx-scheduler-work-space-day
  .dx-scheduler-all-day-appointment
  .dx-scheduler-appointment-content,
.dx-scheduler-work-space-week
  .dx-scheduler-all-day-appointment
  .dx-scheduler-appointment-content,
.dx-scheduler-work-space-work-week
  .dx-scheduler-all-day-appointment
  .dx-scheduler-appointment-content {
  padding: 5px 7px;
  border: solid 1px #f3f3f3;
  border-radius: 5px;
}
.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
  .dx-popup-content
  .dx-list-item
  .dx-list-item-content,
.dx-scheduler-overlay-panel
  .dx-popup-content
  .dx-list-item
  .dx-list-item-content {
  text-align: initial;
  padding: 10px;
}
.dx-scheduler-navigator {
  display: none;
}
.dx-scheduler-header-panel-cell {
  vertical-align: inherit;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-word;
  white-space: normal;
}

.scrolex {
  overflow-x: auto !important;
}
</style>
