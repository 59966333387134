<template>
  <div>
    <v-row class="" v-if="dashboardData.finalResult">
      <v-col>
        <v-alert
          class="mt-4"
          prominent
          text
          outlined
          color="deep-orange "
          type="error"
          icon="mdi-progress-alert"
        >
          <v-row align="center">
            <v-col class="grow">
              <div
                :title="
                  this.$moment(dashboardData.finalResult.publishDate).format('YYYY/MM/DD - h:mm A ')
                "
              >
                {{ $t("home.finalExamMsg") }} |
                {{ this.$moment(dashboardData.finalResult.publishDate).fromNow() }}
              </div>
              <div>
                {{ dashboardData.finalResult.attemptNumber }}
                {{
                  dashboardData.finalResult.courseType
                    ? " - " + dashboardData.finalResult.courseType
                    : ""
                }}
                -
                {{
                  (dashboardData.finalResult.stageName.includes("المرحلة") ? "" : "المرحلة ") +
                  dashboardData.finalResult.stageName
                }}
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="deep-orange "
                :loading="degreesLoading"
                dark
                @click="getFinalDegree(dashboardData.finalResult.guid)"
              >
                {{ $t("home.degreeDownload") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-card class="mx-auto mb-7" flat v-if="dashboardData.exams && dashboardData.exams[0]">
      <v-card-title class="">
        <h4 class="font-weight-light">{{ $t("exam.isLive") }}</h4>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row>
          <v-col
            class="pa-1"
            cols="12"
            xl="3"
            lg="3"
            md="6"
            sm="12"
            v-for="(item, index) in dashboardData.exams"
            :key="index"
          >
            <v-skeleton-loader :loading="loading" translate="trans" type="article, actions">
              <v-card outlined class="pa-0" elevation="0">
                <v-row class="ma-0 pa-0">
                  <v-col v-if="isInRole(2)" cols="7" md="7" sm="5" class="ma-0 pa-0">
                    <div
                      dark
                      :class="
                        $vuetify.rtl
                          ? 'rounded-sm rounded-bl-xl pos pa-2 primary white--text'
                          : 'rounded-sm rounded-br-xl pos pa-2 primary white--text'
                      "
                    >
                      {{ item.datex | moment("YYYY/MM/DD - h:mm A ") }}
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row no-gutters
                  ><v-col cols="12" lg="12" md="12">
                    <v-card-title
                      dir="auto"
                      style="word-break: break-word; height: 85px; overflow: hidden"
                      :title="item.title"
                    >
                      {{ item.title }}
                    </v-card-title>
                  </v-col>
                </v-row>

                <v-row class="text-center pt-0">
                  <v-col class="pa-0">
                    <Timer
                      :starttime="item.startDate"
                      :endtime="item.endDate"
                      :trans="trans"
                      :totalSeconds="item.remainingTime"
                      :type="isInRole(0) && item.restrictedByTime ? 'period' : ''"
                      :nowDate="dashboardData.currentServerDatetime"
                      :class="isdark ? '' : 'custom-timer-color'"
                    >
                    </Timer>
                    <div v-if="isInRole(0)">
                      <v-card-subtitle
                        class="pa-1 px-3"
                        v-if="item.restrictedByTime && item.remainingTime == null"
                        style="color: orange; font-weight: bold"
                      >
                        {{ $t("exam.NotYetEnteredTheExam") }} -
                        <span style="color: red">{{ formatTime(item.restrictedTime) }}</span>
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="pa-1 px-3"
                        v-else-if="item.restrictedByTime && item.remainingTime > 0"
                        style="color: green; font-weight: bold"
                      >
                        {{ $t("exam.EnteredRestrictedPeriod") }}
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="pa-1 px-3"
                        v-else-if="item.restrictedByTime && item.remainingTime <= 0"
                        style="color: red; font-weight: bold"
                      >
                        {{ $t("exam.ThePeriodHasExpired") }}
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="pa-1 px-3"
                        v-else-if="!item.restrictedByTime"
                        style="color: green; font-weight: bold"
                      >
                        {{ $t("exam.Active") }}
                      </v-card-subtitle>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-card-actions>
                          <v-chip outlined v-if="item.examType == 0" color="teal lighten-1" dark>
                            {{ $t("degrees.daily") }}
                          </v-chip>
                          <v-chip outlined v-else-if="item.examType == 3" color="blue" dark
                            >{{ $t("degrees.Monthly") }}
                          </v-chip>
                          <v-chip
                            outlined
                            v-else-if="item.examType == 2"
                            dark
                            color="orange  lighten-1"
                            >{{ $t("degrees.midFinal") }}
                          </v-chip>
                          <v-chip outlined v-else-if="item.examType == 1" dark color="red lighten-1"
                            >{{ $t("degrees.final") }}
                          </v-chip>
                        </v-card-actions>
                        <v-list-item-title class="">
                          {{ item.subjectName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ item.teacherName }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    v-if="isInRole(2)"
                    outlined
                    primary
                    color="primary"
                    @click="showExam(item)"
                  >
                    {{ $t("edit") }}
                    <v-icon class="px-2">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="!item.showResult"
                    :width="isInRole(0) ? '100%' : ''"
                    :disabled="!item.isLive"
                    :primary="isInRole(0) ? true : false"
                    :outlined="isInRole(2) ? true : false"
                    @click="enterExam(item)"
                    color="primary"
                  >
                    {{ item.isLive ? $t("exam.EnterToTheExam") : $t("exam.TheExamIsNotActivated") }}
                    <v-icon class="px-2">mdi-login-variant</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mt-5" flat v-if="dashboardData.forums && dashboardData.forums[0]">
      <v-card-title class="pb-0">
        <v-icon class="me-2" color="orange">mdi mdi-bullhorn-variant</v-icon>
        <h4 class="font-weight-light">{{ $t("forum.Announcements") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="row-pointer"
          :headers="headersForums"
          :items="dashboardData.forums"
          item-key="forumTopicContentGuid"
          width="100%"
          :loading="loading"
          :hide-default-footer="dashboardData.forums && dashboardData.forums.length <= 10"
          :items-per-page="10"
          single-select
          @click:row="openTopicContent"
        >
          <template v-slot:[`item.personName`]="{ item }">
            <v-list-item>
              <v-list-item-avatar
                :color="getColorFromName(item.personName)"
                class="pb-1 white--text"
              >
                {{ getShortName(item.personName) }}
              </v-list-item-avatar>
              <v-list-item-content>
                {{ item.personName }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <v-chip outlined color="orange" label>{{ item.title }}</v-chip>
          </template>

          <template v-slot:[`item.createdDate`]="{ item }">
            <v-chip small outlined label>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    {{ item.createdDate | moment("from", "now") }}
                  </div>
                </template>
                <span>{{ item.createdDate | moment("YYYY/MM/DD hh:mm A") }}</span>
              </v-tooltip></v-chip
            >
          </template>
          <template v-slot:[`item.departmentsName`]="{ item }">
            <div>
              <v-chip small class="me-1 mt-1" outlined label v-if="item.departmentsName">{{
                item.departmentsName
              }}</v-chip>

              <v-chip class="me-1 mt-1" small label outlined v-if="item.stageName">
                {{ item.stageName }} - {{ item.studyName }}</v-chip
              >

              <v-chip class="me-1 mt-1" small outlined label v-if="item.subjectName">
                {{ item.subjectName }}</v-chip
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mt-5" flat v-if="dashboardData.mails && dashboardData.mails[0]">
      <v-card-title class="">
        <h4 class="font-weight-light">{{ $t("mail.unreadMail") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="dashboardData.mails"
          item-key="guid"
          width="100%"
          :loading="loading"
          hide-default-header
          :items-per-page="10"
          :hide-default-footer="dashboardData.mails && dashboardData.mails.length <= 10"
          single-select
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-list-item>
              <!-- <v-list-item-icon>
                 <v-avatar :color="getColorFromName(item.fromName)" size="42">
                    <span class="white--text">{{ getShortName(item.fromName) }}</span>
                  </v-avatar>
              </v-list-item-icon> -->

              <v-list-item-avatar :color="getColorFromName(item.fromName)" class="pb-1 white--text">
                {{ getShortName(item.fromName) }}
              </v-list-item-avatar>
              <v-list-item-content>
                {{ item.fromName }}
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:[`item.attachedFileUrl`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.attachedFileUrl"
                  icon
                  color="green"
                  @click="installFile(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("mail.DownloadTheAttachment") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.datex`]="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    {{ item.datex2 }}
                  </div>
                </template>
                <span>{{ item.datex }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" @click="showMail(item)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Preview") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto mt-2" flat v-if="dashboardData.lectures && dashboardData.lectures[0]">
      <v-card-title>
        <h4 class="font-weight-light">{{ $t("drawer.lectures") }}</h4>
      </v-card-title>

      <!-- <v-row
      class="mx-2"
      >
      <v-col
        cols="12"
        lg="2"
        md="4"
        sm="12"
        v-for="(item, index) in dashboardData.lectures.slice(0, 6)"
        :key="index"
      > -->

      <v-slide-group multiple show-arrows class="py-4" mandatory>
        <v-slide-item v-for="(item, index) in dashboardData.lectures" :key="index" class="mx-2">
          <v-card outlined width="250">
            <v-row class="text-center">
              <v-col class="mt-4">
                <files-icon
                  :fileType="item.filetype"
                  :fileSizeFormat="item.fileSizeFormat"
                  :showBadge="true"
                  :width="75"
                ></files-icon>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title
                      dir="auto"
                      :title="item.lectureName"
                      class="mb-1"
                      style="text-align: center; overflow: hidden !important"
                    >
                      {{ item.lectureName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ ` ${item.subjectName}` }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{
                      ` ${item.stageName} - ${item.studyName}`
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ item.datex | moment("YYYY/MM/DD - h:mm a ") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn outlined @click="openLec(item)" class="teal" dark width="100%"
                >{{ $t("lecOne.lecOpen") }}
                <v-icon class="px-2">mdi-file-eye-outline</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <!-- </v-col>
    </v-row> -->
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import Timer from "../../../../components/exams/timer.vue";
import FilesIcon from "../../../../components/filesIcon.vue";
import { bus } from "../../../../main";

export default {
  name: "SubDashboard",
  components: {
    Timer,
    FilesIcon,
  },
  data() {
    return {
      loading: false,
      isdark: localStorage.getItem("darkMode") == "true",
      dashboardData: {},
      trans: {
        day: this.$t("exam.day"),
        hours: this.$t("exam.hours"),
        minutes: this.$t("exam.minutes"),
        seconds: this.$t("exam.seconds"),
        expired: this.$t("exam.expired"),
        running: this.$t("exam.running"),
        upcoming: this.$t("exam.upcoming"),
        status: {
          expired: this.$t("exam.status.expired"),
          running: this.$t("exam.status.running"),
          upcoming: this.$t("exam.status.upcoming"),
        },
      },
      headers: [
        {
          value: "image",
          show: true,
        },
        {
          text: this.$t("mail.title"),
          value: "title",
          show: true,
        },

        {
          text: this.$t("mail.Attachments"),
          value: "attachedFileUrl",
          show: true,
        },

        {
          text: this.$t("date"),
          value: "datex",
          show: true,
        },
        {
          text: this.$t("mail.actions"),
          value: "actions",
          show: true,
        },
      ],
      headersForums: [
        {
          value: "personName",
          show: true,
          width: "350",
          sortable: false,
        },
        { value: "title", show: true, sortable: false },
        { value: "createdDate", show: true, sortable: false },
        { value: "departmentsName", show: true, sortable: false },
      ],
      degreesLoading: false,
    };
  },
  created() {
    this.getDashboard();
  },
  computed: {},

  methods: {
    getFinalDegree(guid) {
      this.degreesLoading = true;
      this.axios({
        url: `Degrees/GetStudentFinalResult?guid=${guid}`,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "finalDegrees";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch()
        .finally(() => {
          this.degreesLoading = false;
        });
    },
    installFile(data) {
      window.open(`${data.attachedFileUrl}`);
    },
    getColorFromName(name) {
      return this.stringToHslColor(name, 40, 50);
    },
    getShortName(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map((str) => (str ? str[0].toUpperCase() : ""))
        .join(".");
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
    openLec(item) {
      this.axios
        .post(`Uploader/Download`, {
          fileUrl: item.fileUrl,
        })
        .then((res) => {
          window.open(res.data.data.url, "_blank");
        })
        .catch((err) => {})
        .finally(() => {
          // this.overlay = false;
        });
      // window.open(item.fileUrl, "_blank");
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    getDashboard() {
      this.loading = true;
      this.axios
        .get(`Dashboard/Get`)
        .then((res) => {
          this.dashboardData = res.data.data;
          this.dashboardData.mails.forEach((e) => {
            e.datex2 = moment(e.datex).fromNow();
            e.datex = moment(e.datex).format("YYYY/MM/DD - h:mm a ");
          });
          for (let i = 0; i < this.dashboardData.lectures.length; i++) {
            this.dashboardData.lectures[i].filetype = this.get_url_extension(
              this.dashboardData.lectures[i].fileUrl
            );
          }
          // console.log(res.data.data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    showExam(item) {
      this.$router.push({
        path: `showExam=${item.guid}`,
        params: { id: item.guid },
      });
    },
    enterExam(item) {
      if (this.isInRole(0) && item.restrictedByTime && item.remainingTime == null) {
        this.$swal
          .fire({
            title: this.$t("AreYouSureAboutTheOperation"),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
          })
          .then((result) => {
            if (result.value) {
              this.$router.push({
                path: `enterExam=${item.guid}`,
                params: { id: item.guid },
              });
            }
          });
      } else {
        this.$router.push({
          path: `enterExam=${item.guid}`,
          params: { id: item.guid },
        });
      }
    },
    showMail(data) {
      if (!data.isRead) {
        this.axios
          .post("Mails/MarkAsRead", [data.guid])
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      }
      this.$router.push({
        path: `showMail=${data.guid}&0`,
        params: { id: data.guid, type: 0 },
      });
    },
    formatTime(totalSeconds) {
      var hours = 0;
      var minutes = 0;
      var seconds = 0;

      if (totalSeconds > 0) {
        const remainingSeconds = totalSeconds % 3600;
        hours = parseInt(totalSeconds / 60 / 60)
          .toString()
          .padStart(2, "0");
        minutes = parseInt(remainingSeconds / 60)
          .toString()
          .padStart(2, "0");
        seconds = parseInt(remainingSeconds % 60)
          .toString()
          .padStart(2, "0");
      }

      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    openTopicContent(e) {
      this.$router
        .push({
          path: `/forums?topicGuid=${e.forumTopicGuid}`,
          replace: true,
        })
        .catch(() => {})
        .finally(() => {
          bus.$emit("selectContent", e.forumTopicContentGuid);
        });
    },
  },
};
</script>

<style lang="css">
.custom-timer-color .number {
  background: #eeeeee !important;
  color: black !important;
  font-weight: bold !important;
}
.custom-timer-color .format {
  color: black !important;
  font-weight: bold !important;
}
</style>

<style lang="scss">
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 12px !important;
  flex: 0 1 15px !important;
}
</style>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
